body {
    font-family: 'Roboto', 'sans-serif';
    height: 100vh;
    margin: 0;
}

.logo {
    background-image: url('./images/DocutechLogo.png');
    background-repeat: no-repeat;
    width: 222px;
    height: 50px;
}

.close {
    margin-top: 5px;
    border: #fff 1px solid;
    width: 20px;
    height: 20px;
}

.header {
    display: flex;
    height: 5rem;
    width: 100%;
    align-items: center;
    padding-left: 2.5rem;
}

.sideNav {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    height: 100vh;
    margin-top: -5rem;
    padding-top: 5rem;
    overflow: hidden;
}

.sideNavSideBar {
    margin-top: 5rem;
    grid-column: span 3 / span 3;
    background-color: #143A6B;
    overflow-y: scroll;
    overflow-x: hidden;

}

.sideNavSideBar {
    grid-column: span 2 / span 2;
}


.sideNavSideBar::-webkit-scrollbar {
    width: 6px;
    height: 6px
}

.sideNavSideBar::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0);
    border: none;

}

.sideNavSideBar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgb(86, 147, 247, 0.5);

}
 
.sideNavNavPage {
    margin-top: 5rem;
    grid-column: span 10 / span 10;
    overflow: scroll;
}

.sideNavNavPage {
    grid-column: span 10 / span 10;
}
 
.sidebar {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.sidebarlink {
    font-size: 15px;
    line-height: 1.75rem;
    padding: 0.5rem;
}

.footer {
    font-family: 'Roboto', 'sans-serif';
}

.config {
    font-size: 3.75rem;
    line-height: 1;
    padding: 10px;
}

.MuiCardHeader-title {
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

.MuiPaper-root {
    padding: 5px !important;
}

/* Config Packages */
.configPackageContainer  {
    display: flex;
    width: 100%;
}

.configPackageSubContainer-Left  {
    flex: 0 0 300px;
}

.configPackageSubContainer-Right  {
    flex: 1 1 auto; 
    display: flex;
    flex-direction: column; 
    overflow: auto;
}

/* Config Package Details */
.packageDetailsContainer {
    display: flex;
    flex-direction: column;
}

.packageDetails-Row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.packageDetails-Row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.packageDetails-Row1-Left-Section {
    display: flex;
    align-items: center;
}

.packageDetails-Row1-Right-Section {
    display: flex;
    align-items: center;
}

.packageDetails-Row2-Left-Section {
    display: flex;
    align-items: center;
}

.packageDetails-Row2-Right-Section {
    display: flex;
    align-items: center;
}

/*
    Config Servers Styles
*/  

.configServersContainerConformX  {
    display: 'flex';
    flex-direction: 'column';
    margin: 2;
    width: "100%";
}

/* 
    Config Package Styles
*/

/* Config Packages */
.configPackageContainer  {
    display: flex;
    width: 100%;
}

.configPackageSubContainer-Left  {
    flex: 0 0 300px;
}

.configPackageSubContainer-Right  {
    flex: 1 1 auto; 
    display: flex;
    flex-direction: column; 
    overflow: auto;
}

/* Config Package Details */
.packageDetailsContainer {
    display: flex;
    flex-direction: column;
}

.packageDetails-Row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.packageDetails-Row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.packageDetails-Row1-Left-Section {
    display: flex;
    align-items: center;
}

.packageDetails-Row1-Right-Section {
    display: flex;
    align-items: center;
}

.packageDetails-Row2-Left-Section {
    display: flex;
    align-items: center;
}

.packageDetails-Row2-Right-Section {
    display: flex;
    align-items: center;
}

/* 
    Config Delivery Type Styles
*/

/* Config Delivery Types */
.configDeliveryTypesContainer  {
    display: flex;
    width: 100%;
}

.configDeliveryTypeSubContainer-Left  {
    flex: 0 0 300px;
}

.configDeliveryTypeSubContainer-Right  {
    flex: 1 1 auto; 
    display: flex;
    flex-direction: column; 
    overflow: auto;
}


/* Config Delivery Type Details */
.deliveryTypeDetailsContainer {
    display: flex;
    flex-direction: column;
}

.deliveryTypeDetails-Row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.deliveryTypeDetails-Row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.deliveryTypeDetails-Row1-Left-Section {
    display: flex;
    align-items: center;
}

.deliveryTypeDetails-Row1-Right-Section {
    display: flex;
    align-items: center;
}

.deliveryTypeDetails-Row2-Left-Section {
    display: flex;
    align-items: center;
}

.deliveryTypeDetails-Row2-Right-Section {
    display: flex;
    align-items: center;
}

/* Config Other Controls  */
.configOtherControlsContainer  {
    display: flex;
    width: 100%;
}

.configOtherControls-Left  {
    flex: 0 0 300px;
}

.configOtherControls-Right  {
    flex: 1 1 auto; 
    display: flex;
    flex-direction: column; 
    overflow: auto;
}

.height100 {
    height: 100%;
    display: flex;
    align-items: center;
}

.flexCenter{
    display: flex;
    justify-content: space-around;
    align-content: center;
}

.flexLeft{
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
}

.flexRight{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
}


.dtcell{
    max-width: 14rem;;
}

.dtcelltrunc {
    text-overflow: ellipsis;
    max-width: 14rem;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
}