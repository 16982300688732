xPathGrid {
    width: 100%;
    height: 100%;
}

.xPathGrid table tbody tr td:first-of-type {
    width: 20px;
}

.xPathGrid table tbody tr td:nth-of-type(2) {
    width: 20px;
}

.xPathGrid table tbody tr td:nth-of-type(3) {
    width: 150px;
}

.xPathGrid table tbody tr td:nth-of-type(4) {
    width: 250px;
}